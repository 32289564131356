import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import {
  Eatfresh_Basic_User_Type_Enum,
  Eatfresh_Order_Status_Enum,
  Eatfresh_Password_Reset_Token_Status_Enum,
} from '@/gql/graphql';
import { type BeforeInstallPromptEvent, useUserStore } from '@/stores/user';
import { useOrderStore } from '@/stores/order';

const scanShortcutRoutes = (given_ids: string[]) => {
  return given_ids.map((given_id) => {
    return {
      path: `/${given_id}`,
      redirect: () => {
        return {
          name: 'customer-fridge-stock',
          params: { given_id: given_id },
          query: { cabinet_id: given_id },
        };
      },
    };
  });
};

const mockShortcutRoutes = (given_ids: string[]) => {
  return given_ids.map((given_id) => {
    return {
      path: `/${given_id}/mock`,
      redirect: () => {
        return { name: 'fridge-mock', params: { given_id: given_id } };
      },
    };
  });
};
const updateRouterSessionStorage = (newRoute: string) => {
  sessionStorage.setItem('last-url', newRoute);
};
const readRouterSessionStorage = () => {
  return sessionStorage.getItem('last-url');
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // General
    {
      path: '/',
      redirect: '/customer/home',
    },

    // Admin
    {
      path: '/admin',
      component: () => import('../views/admin/AdminLayout.vue'),
      children: [
        {
          path: '',
          name: 'admin-home',
          component: () => import('../views/admin/AdminHomepage.vue'),
        },
        {
          path: 'fridge/:id',
          name: 'admin-fridge-stock',
          component: () => import('../views/admin/AdminFridgeStockTable.vue'),
        },
        {
          path: 'fridge/target/:fridgeId',
          name: 'admin-fridge-target-stock',
          component: () =>
            import('../views/admin/AdminFridgeTargetStockTable.vue'),
        },
        {
          path: 'login',
          name: 'admin-login',
          component: () => import('@/components/LoginModal.vue'),
        },
        {
          path: 'profile',
          name: 'admin-profile',
          component: () => import('../views/admin/AdminUserProfile.vue'),
        },
        {
          path: 'article-management',
          name: 'admin-article-management',
          component: () => import('../views/admin/AdminArticleTable.vue'),
        },
        {
          path: 'rfid-tag-management',
          name: 'admin-rfid-tag-management',
          component: () => import('../views/admin/AdminRfidTable.vue'),
        },
        {
          path: 'fridge-management',
          name: 'admin-fridge-management',
          component: () => import('../views/admin/AdminFridgeTable.vue'),
        },
        {
          path: 'order/:id',
          name: 'admin-order-positions',
          component: () =>
            import('../views/admin/AdminOrderPositionsTable.vue'),
        },
        {
          path: 'user-management',
          name: 'admin-user-management',
          component: () => import('../views/admin/AdminUserTable.vue'),
        },
        {
          path: 'order-management',
          name: 'admin-order-management',
          component: () => import('../views/admin/AdminOrderTable.vue'),
        },
        {
          path: 'warehouse-management',
          name: 'admin-warehouse-management',
          component: () => import('../views/admin/AdminWarehouseTable.vue'),
        },
        {
          path: 'places-management',
          name: 'admin-places-management',
          component: () => import('../views/admin/AdminPlacesTable.vue'),
        },
        {
          path: 'warehouse-order-management',
          name: 'admin-warehouse-order-management',
          component: () =>
            import('../views/admin/AdminWarehouseOrderTable.vue'),
        },
        {
          path: 'warehouse-order/:id',
          name: 'admin-warehouse-order-positions',
          component: () =>
            import('../views/admin/AdminWarehouseOrderPositionsTable.vue'),
        },
        {
          path: 'vat-categories',
          name: 'admin-vat-categories',
          component: () => import('../views/admin/AdminVatTable.vue'),
        },
        {
          path: 'password-reset/:resetToken',
          name: 'admin-password-reset',
          component: () => import('@/views/admin/AdminPasswordReset.vue'),
        },
        {
          path: 'reset-password',
          name: 'admin-reset-password-request',
          component: () => import('@/views/admin/PasswordResetPage.vue'),
        },
      ],
    },

    // Customer
    {
      path: '/customer',
      component: () => import('../views/customer/CustomerLayout.vue'),
      children: [
        {
          path: 'fridge-stock/:given_id',
          name: 'customer-fridge-stock',
          component: () => import('../views/customer/CustomerFridgeStock.vue'),
        },
        {
          path: 'fridge-stock/payment/:given_id',
          name: 'customer-payment',
          component: () => import('../views/customer/CustomerPaymentCta.vue'),
        },
        {
          path: 'fridge-stock/demo/payment/:given_id',
          name: 'customer-payment-demo',
          component: () => import('../views/mock/StripePaymentMockup.vue'),
        },
        {
          path: 'login',
          name: 'customer-login',
          component: () => import('../views/customer/CustomerLoginScreen.vue'),
        },
        {
          path: 'register',
          name: 'customer-register',
          component: () =>
            import('../views/customer/CustomerRegisterScreen.vue'),
        },
        {
          path: 'agb',
          name: 'customer-agb',
          component: () =>
            import('../views/customer/CustomerProvidedSuccessfully.vue'),
        },
        {
          path: 'purchase-confirmation',
          name: 'customer-purchase-confirmation',
          component: () =>
            import('../views/customer/CustomerPurchaseComplete.vue'),
        },
        {
          path: 'order-invoice/:orderId',
          name: 'customer-order-invoice',
          component: () => import('../views/customer/CustomerInvoice.vue'),
        },
        {
          path: 'order-canceled',
          name: 'customer-order-canceled',
          component: () =>
            import('../views/customer/CustomerOrderCanceled.vue'),
        },
        {
          path: 'order-disturbance',
          name: 'customer-order-disturbance',
          component: () =>
            import('../views/customer/CustomerOrderDisturbance.vue'),
        },
        {
          path: 'order-timeout',
          name: 'customer-order-timeout',
          component: () => import('../views/customer/CustomerOrderTimeout.vue'),
        },
        {
          path: 'fridge-selection',
          name: 'customer-fridge-selection',
          component: () =>
            import('../views/customer/Navbar/CustomerFridgeScanner.vue'),
        },
        {
          path: 'profile',
          name: 'customer-profile',
          redirect: () => {
            return { name: 'customer-map' };
          },
        },
        {
          path: 'home',
          name: 'customer-home',
          component: () => import('../views/customer/CustomerHome.vue'),
        },
        {
          path: 'settings',
          children: [
            {
              path: '',
              name: 'customer-settings',
              component: () =>
                import('../views/customer/Navbar/CustomerSettings.vue'),
            },
            {
              path: 'payment',
              name: 'customer-payment-settings',
              component: () =>
                import(
                  '../views/customer/settings/CustomerPaymentSettings.vue'
                ),
            },
            {
              path: 'account',
              name: 'customer-account-settings',
              component: () =>
                import(
                  '../views/customer/settings/CustomerAccountSettings.vue'
                ),
            },
            {
              path: 'security',
              name: 'customer-security-settings',
              component: () =>
                import(
                  '../views/customer/settings/CustomerSecuritySettings.vue'
                ),
            },
          ],
        },
        {
          path: 'orders',
          name: 'customer-orders',
          component: () =>
            import('../views/customer/Navbar/CustomerOrders.vue'),
        },
        {
          path: 'map',
          name: 'customer-map',
          component: () => import('../views/customer/Navbar/CustomerMap.vue'),
        },
        //Route for the mandatory password change for e.g refiller
        {
          path: 'password-change',
          name: 'customer-password-change',
          component: () =>
            import('../views/customer/CustomerPasswordChange.vue'),
        },
        {
          path: 'password-reset/:resetToken',
          name: 'customer-password-reset',
          component: () =>
            import('../views/customer/CustomerPasswordReset.vue'),
        },
        {
          path: 'fridgeQrCodes',
          name: 'customer-fridge-qrcodes',
          component: () =>
            import('../views/customer/CustomerFridgeSelection.vue'),
        },
        {
          path: 'article/:id',
          name: 'customer-article-details',
          component: () =>
            import('@/views/customer/CustomerArticleDetails.vue'),
        },
        {
          path: 'stripe-mockup/:given_id',
          name: 'stripe-mockup',
          component: () => import('@/views/mock/StripePaymentMockup.vue'),
        },
        {
          path: 'how-it-works',
          name: 'how-it-works',
          component: () =>
            import('@/views/customer/Navbar/CustomerTutorial.vue'),
        },
      ],
    },
    // Supplier
    {
      path: '/supplier',
      component: () => import('@/views/supplier/SupplierLayout.vue'),
      children: [
        {
          path: '',
          redirect: { name: 'supplier-home' },
        },
        {
          path: 'login',
          name: 'supplier-login',
          component: () => import('@/views/supplier/SupplierLogin.vue'),
        },
        {
          path: 'home',
          name: 'supplier-home',
          component: () => import('@/views/supplier/SupplierHome.vue'),
        },
        {
          path: 'start-labeling',
          name: 'supplier-start-labeling',
          component: () =>
            import('@/views/supplier/labeling/SupplierStartLabeling.vue'),
        },
        {
          path: 'start-labeling-scanner-flow',
          name: 'supplier-start-labeling-scanner-flow',
          component: () =>
            //import('@/components/UniversalScanner.vue')
            import(
              '@/views/supplier/labeling/SupplierLabelingPrefillScanner.vue'
            ),
        },
        {
          path: 'labeling-in-progress/:id',
          name: 'supplier-labeling-in-progress',
          component: () =>
            import('@/views/supplier/labeling/SupplierLabelingInProgress.vue'),
        },
        {
          path: 'single-scan-mode/:id',
          name: 'supplier-single-scan-mode',
          component: () =>
            import('@/views/supplier/labeling/SupplierSingleScanMode.vue'),
        },
        {
          path: 'labeling-complete/:id',
          name: 'supplier-labeling-complete',
          component: () =>
            import('@/views/supplier/labeling/SupplierLabelingComplete.vue'),
        },
        {
          path: 'fridge-orders',
          name: 'supplier-fridge-orders',
          component: () =>
            import('@/views/supplier/commissioning/SupplierFridgeOrders.vue'),
        },
        {
          path: 'start-commissioning',
          name: 'supplier-start-commissioning',
          component: () =>
            import(
              '@/views/supplier/commissioning/SupplierStartCommissioning.vue'
            ),
        },
        {
          path: 'fridge-order/:id',
          name: 'supplier-fridge-order',
          component: () =>
            import('@/views/supplier/commissioning/SupplierFridgeOrder.vue'),
        },
        {
          path: 'fill-boxes',
          name: 'supplier-fill-boxes',
          component: () => import('@/views/supplier/SupplierFillBoxes.vue'),
        },
        {
          path: 'box-details',
          name: 'supplier-box-details',
          component: () => import('@/views/supplier/SupplierBoxDetails.vue'),
        },
        {
          path: 'commissioning-in-progress/:id',
          name: 'supplier-commissioning-in-progress',
          component: () =>
            import(
              '@/views/supplier/commissioning/SupplierCommissioningInProgress.vue'
            ),
        },
        {
          path: 'commissioning-complete/:id',
          name: 'supplier-commissioning-complete',
          component: () =>
            import(
              '@/views/supplier/commissioning/SupplierCommissioningComplete.vue'
            ),
        },
        {
          path: 'select-storage-container',
          name: 'supplier-select-storage-container',
          component: () =>
            import(
              '@/views/supplier/commissioning/SupplierSelectStorageContainer.vue'
            ),
        },
        {
          path: 'receipt-inspection/:id',
          name: 'supplier-receipt-inspection',
          component: () =>
            import('@/views/supplier/warehouse/SupplierReceiptInspection.vue'),
        },
        {
          path: 'slot',
          name: 'supplier-slot',
          component: () =>
            import('@/components/unreleased_features/SlotMachineContainer.vue'),
        },
        {
          path: 'goods-received',
          name: 'supplier-goods-received',
          component: () =>
            import('@/views/supplier/warehouse/SupplierGoodsReceived.vue'),
        },
        {
          path: 'provide-article-box-capacity/:id',
          name: 'supplier-provide-article-box-capacity',
          component: () =>
            import(
              '@/views/supplier/labeling/SupplierSpecifyArticleBoxCapacity.vue'
            ),
        },
        {
          path: 'process-shipment-tutorial/:warehouse_order_id',
          name: 'supplier-process-shipment-tutorial',
          component: () =>
            import('@/views/supplier/warehouse/ProcessShipmentTutorial.vue'),
        },
        {
          path: 'label-goods',
          name: 'label-shipment-goods',
          component: () =>
            import('@/views/supplier/warehouse/SupplierLabelOrder.vue'),
        },
        {
          path: 'conflicting-positions',
          name: 'supplier-conflicting-labeling-order-positions',
          component: () =>
            import(
              '@/views/supplier/labeling/SupplierConflictingLabelingPositions.vue'
            ),
        },
      ],
    },

    // Mock and General
    {
      path: '/refiller/refill/:given_id/:id',
      name: 'refillment',
      component: () => import('@/views/refiller/RefillerRefillStock.vue'),
    },
    {
      path: '/refiller/refill/refill-confirmation',
      name: 'refiller-refill-confirmation',
      component: () => import('../views/refiller/RefillerRefillComplete.vue'),
    },
    // General stuff
    {
      path: '/mock/fridge-select',
      name: 'fridge-select',
      component: () => import('../views/general/FridgeMockSelection.vue'),
    },
    {
      path: '/mock/fridge/:given_id',
      name: 'fridge-mock',
      component: () => import('../views/mock/MockFridge.vue'),
    },
    {
      path: '/mock/scanner',
      name: 'scanner-mock',
      component: () => import('../views/mock/MockScanner.vue'),
    },

    // shortcuts
    ...scanShortcutRoutes(['rollup-1', 'demo-1']),
    ...mockShortcutRoutes(['rollup-1', 'demo-1']),

    // TODO: SET given_id of selfly fridge.
    {
      path: '/demo-s',
      name: 'demo-s',
      redirect: () => {
        return {
          name: 'customer-fridge-stock',
          params: { given_id: 'if-15-2-2022-30b8ea' },
          query: { cabinet_id: 'if-15-2-2022-30b8ea' },
        };
      },
    },
  ],
});

const anonymousCustomerAllowedRoutes = [
  'customer-home',
  'customer-payment',
  'customer-payment-demo',
  'customer-login',
  'customer-register',
  'customer-fridge-selection',
  'customer-anonymous-payment-info',
  'customer-fridge-stock',
  'how-it-works',
  'customer-map',
  'customer-orders',
  'customer-settings',
  'customer-article-details',
  'customer-fridge-qrcodes',
  'customer-password-reset',
  'payment-cta',
];

router.beforeEach(async (to, from) => {
  const orderStore = useOrderStore();
  const authStore = useAuthStore();

  //unauthenticated
  if (to.path.startsWith('/general')) return true;

  //attempt restoring cookie
  if (!authStore.authToken) {
    try {
      const restoredFromCookie = await authStore.restoreTokenFromCookie();
      authStore.setAuthToken(restoredFromCookie.value);
    } catch (e) {
      console.log('Could not restore token from cookie');
    }
  }

  const lastRoute1 = readRouterSessionStorage();
  const lastRouteIsOrderRoute =
    lastRoute1 && lastRoute1.includes('customer/fridge-stock');
  const lastRouteInactive1 = !window.location.href.includes(
    'customer/fridge-stock',
  );
  if (lastRouteIsOrderRoute && lastRouteInactive1) {
    let lastRouteURL = null;
    try {
      lastRouteURL = new URL(lastRoute1);
    } catch (e) {
      sessionStorage.removeItem('last-url');
      return;
    }
    const routeOrderId = lastRouteURL.searchParams.get('orderId');
    const lastRouteWasOrderWithId =
      routeOrderId && lastRouteURL.href.includes('customer/fridge-stock');
    if (lastRouteWasOrderWithId) {
      await orderStore.fetchOrderById(routeOrderId);
      const order = orderStore.orders[0];
      if (order && order.order_status === Eatfresh_Order_Status_Enum.Draft) {
        await orderStore.cancelOrder(routeOrderId);
      }
    }
  }

  const lastRoute2 = readRouterSessionStorage();
  const lastRouteIsPasswordResetRoute =
    lastRoute2 &&
    (lastRoute2.includes('customer/password-reset') ||
      lastRoute2.includes('admin/password-reset'));
  const lastRouteInactive2 = window.location.href !== lastRoute2;
  if (lastRouteIsPasswordResetRoute && lastRouteInactive2) {
    const urlParts = lastRoute2.split('/');
    console.log('store', urlParts);
    const res = await authStore.getPasswordResetToken({
      token: urlParts[urlParts.length - 1],
    });
    const token = res.data.eatfresh_password_reset_token[0];
    if (
      token &&
      token.status === Eatfresh_Password_Reset_Token_Status_Enum.Pending
    ) {
      await authStore.updatePasswordResetToken({
        token_id: token.id,
        used: true,
        status: Eatfresh_Password_Reset_Token_Status_Enum.Expired,
      });
    }
  }

  updateRouterSessionStorage(window.location.href);

  // now there is either a valid token in authstore or not.
  // admin-path validation
  if (to.path.startsWith('/admin')) {
    if (
      to.name === 'admin-reset-password-request' ||
      to.name === 'admin-password-reset'
    )
      return true; // edgecase where navigation to reset route must be possible
    if (!authStore.authToken && to.name !== 'admin-login') {
      // push to login if no authtoken
      return { name: 'admin-login' };
    }
    if (authStore.authToken) {
      if (authStore.role !== Eatfresh_Basic_User_Type_Enum.Admin) {
        await authStore.logOut();
        return { name: 'admin-login' };
      }
    }
  }

  if (to.path.startsWith('/supplier')) {
    if (!authStore.authToken && to.name !== 'supplier-login') {
      return { name: 'supplier-login' };
    }
    if (authStore.authToken) {
      if (authStore.role !== Eatfresh_Basic_User_Type_Enum.Supplier) {
        await authStore.logOut();
        return { name: 'supplier-login' };
      }
    }
  }
  // customer-path validation
  if (to.path.startsWith('/customer')) {
    if (
      !authStore.authToken &&
      !anonymousCustomerAllowedRoutes.includes(to.name as string)
    ) {
      return { name: 'customer-login', query: { redirect: to.path } };
    }
    if (authStore.authToken) {
      if (!authStore.role) return { name: 'customer-login' }; // couldnt be found
      if (
        to.name === 'customer-password-change' &&
        !authStore.requiresPasswordChange
      )
        return router.back();
      if (
        authStore.requiresPasswordChange === true &&
        to.name !== 'customer-password-change'
      ) {
        return { name: 'customer-password-change' };
      }
      if (authStore.role === Eatfresh_Basic_User_Type_Enum.Admin) {
        //TODO change to enum
        console.log('cannot log into customer as admin');
        const logoutResponse = await authStore.logOut();
        authStore.authToken = null;
        return { name: 'customer-login' }; // router.back() //Customer cannot access admin page through general authtoken
      }
    }
    const userIsAuthenticated =
      authStore.authToken &&
      authStore.role !== Eatfresh_Basic_User_Type_Enum.Anonymous;
    const requestedRouteIsAuthRoute =
      to.name === 'customer-register' || to.name === 'customer-login';
    if (userIsAuthenticated && requestedRouteIsAuthRoute) {
      return { name: 'customer-map' };
    }
  }

  if (to.name === 'scanner-mock') {
    if (!authStore.authToken) {
      return { name: 'supplier-login', query: { reroute: 'scanner-mock' } };
    }
  }
  return true;
});
router.afterEach((to, from) => {
  console.log(window.location.href);
  updateRouterSessionStorage(window.location.href);
});
window.addEventListener('beforeinstallprompt', (e) => {
  console.log('BeforeInstallPromptEvent');
  e.preventDefault();
  const userStore = useUserStore();
  userStore.pwaInstallation.beforeInstallPromptEvent =
    e as BeforeInstallPromptEvent;
});
export default router;
